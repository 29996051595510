/* Header
------------------------------------------------------------*/

.c-header {}


/* Main menu
------------------------------------------------------------*/

body.is-modal-open {
    overflow: hidden;
}

body.is-modal-open .l-header__nav-btn {
    display: none;
}

.c {
    &-header {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        color: white;
        padding: 0 20px;
    }
    &-header__list {
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        max-width: 1010px;
        margin: 33px auto;
        padding-right: 49px;
        align-items: center;
    }
    &-header__home {
        font-size: 3.2rem;
        letter-spacing: 3px;
        z-index: 2;
        @media only screen and (max-width: 767px) {
            font-size: 2.8rem;
        }
    }
    &-header__fix {
        width: 100%;
        position: fixed;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        max-width: 1148px;
        margin: 0 auto;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: end;
        justify-content: flex-end;
        height: 114px;
        -webkit-box-align: center;
        align-items: center;
        padding: 0 20px;
        @media only screen and (max-width: 516px) {
            padding: 0 10px;
        }
    }
    &-header__menu {
        margin-top: 51px;
        text-align: center;
        margin-bottom: 133px;
        li {
            margin-bottom: 24px;
        }
    }
    &-header__copy {
        letter-spacing: 1.8px;
    }
}

.l-nav__button {
    display: flex;
    justify-content: flex-end;
    max-width: 1040px;
    margin: 27px auto;
    width: 100%;
    align-items: center;
    padding: 0 20px;
}

.l-header__nav-btn.sp {
    display: none;
}

@media only screen and (max-width: 1024px) {
    .l-header__nav-btn.sp {
        display: block;
        margin-left: 40px;
    }
    .l-header__nav-btn.pc {
        display: none;
    }
}

#js-nav-btn {
    position: relative;
    width: 52px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 50%;
    flex-direction: column;
    cursor: pointer;
    &.is-fixed {
        background-color: #8FC31F;
        span {
            background-color: white;
        }
        b {
            color: white;
        }
    }
}

.l-header__nav-btn.act {
    background-color: unset;
}

.l-header__nav-btn span {
    display: block;
    background-color: #8FC31F;
    height: 2px;
    width: 26.42px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    margin-bottom: 4px;
}

.l-header__nav-btn b {
    display: block;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: .1em;
    font-size: 10px;
    text-align: center;
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    white-space: nowrap;
    font-size: 0.882rem;
    color: #8FC31F;
}

.l-header__nav-btn.act span {
    background-color: white;
}

.l-header__nav-btn.act b {
    color: white;
}

.l-header__nav-btn b::before {
    content: "MENU";
}

.l-header__nav-btn.act span {
    position: absolute;
    top: calc(50% - 1px);
    left: 4px;
    margin: 0;
}

.l-header__nav-btn.act span {
    left: 0;
    right: 0;
}

.l-header__nav-btn.act span:nth-child(1) {
    top: 12px;
    transform: rotate(45deg);
    height: 2px;
    width: 28px;
}

.l-header__nav-btn.act span:nth-child(2) {
    opacity: 0;
}

.l-header__nav-btn.act span:nth-child(3) {
    top: 12px;
    transform: rotate(-45deg);
    height: 2px;
    width: 28px;
}

.l-header__nav-btn.act b {
    display: none;
}

.l-header__nav-btn.act b::before {
    content: "CLOSE";
}

_:-ms-lang(x)::-ms-backdrop,
.l-header__nav-btn>b {
    transform: scale(0.9) translateX(-5%);
}

_:-ms-lang(x)::-ms-backdrop,
.l-header__nav-btn.act>b {
    transform: scale(0.9) translateX(-7%);
}

.l-nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #8FC31F;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: opacity 0.3s cubic-bezier(0, 0, 0.19, 0.99);
    transition: opacity 0.3s cubic-bezier(0, 0, 0.19, 0.99);
    overflow: auto;
    padding: 0 20px;
    overflow-x: hidden;
}

@media only screen and (max-width: 767px) {
    .l-nav {
        padding: 0;
    }
}

.l-nav.show {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 999;
    opacity: 1;
    pointer-events: auto;
}

.l-nav__button .l-header__nav-btn.act {
    width: 28px;
    position: relative;
    display: -webkit-box;
    display: flex;
    height: 28px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}