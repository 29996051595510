/*------------------------------------------------------------
footer
------------------------------------------------------------*/

.c {
    &-footer {
        background-color: #8FC31F;
        padding: 49px 20px 33px;
        position: relative;
        color: white;
    }
    &-footer__list {
        max-width: 841.62px;
        justify-content: space-between;
        display: flex;
        align-items: center;
        @media only screen and (max-width: 767px) {
            flex-wrap: wrap;
            justify-content: center;
        }
    }
    &-footer__home {
        font-size: 3.2rem;
        letter-spacing: 4px;
        @media only screen and (max-width: 767px) {
            font-size: 2.8rem;
            margin-bottom: 20px;
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }
    &-footer__menu {
        width: 100%;
        max-width: 564.6px;
        justify-content: space-between;
        display: flex;
        @media only screen and (max-width: 1024px) {
            max-width: 531.6px;
        }
        @media only screen and (max-width: 516px) {
            max-width: 350px;
            margin: 0 auto;
            flex-wrap: wrap;
            li {
                width: 40%;
                margin-bottom: 10px;
                &:nth-child(even) {
                    width: 60%;
                }
            }
        }
    }
    &-footer__copy {
        text-align: center;
        font-size: 1.6rem;
        margin-top: 64px;
        letter-spacing: 3px;
        @media only screen and (max-width: 516px) {
            font-size: 1.5rem;
            margin-top: 53px;
            letter-spacing: 0px;
        }
    }
    &-footer__top {
        width: 74.14px;
        position: absolute;
        cursor: pointer;
        right: 0;
        bottom: 0;
    }
}