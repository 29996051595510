/*------------------------------------------------------------
Css of home page
------------------------------------------------------------*/

.p-single__main {
    padding: 0 20px;
}

.p-single__list {
    display: flex;
    justify-content: space-between;
    max-width: 888.63px;
    @media only screen and (max-width: 767px) {
        flex-wrap: wrap;
    }
}

.p-single__left {
    @media only screen and (max-width: 767px) {
        width: 100%;
    }
}

.p-top2__english {}

.p-top2__japan {}

.p-single__post {
    width: 79%;
    margin-top: 15px;
    @media only screen and (max-width: 767px) {
        width: 100%;
        margin-top: 40px;
    }
}

.p-single__info {
    padding-bottom: 45px;
    border-bottom: 1px solid #8FC31F;
    @media only screen and (max-width: 516px) {
        padding-bottom: 20px;
    }
}

.p-single__date {
    letter-spacing: 2.7px;
    @media only screen and (max-width: 516px) {
        letter-spacing: 2px;
    }
}

.p-single__title {
    font-size: 2.4rem;
    margin-top: 3px;
    letter-spacing: 5px;
    @media only screen and (max-width: 516px) {
        font-size: 2.2rem;
        letter-spacing: 2px;
    }
}

.p-single__content {
    line-height: 1.75;
    letter-spacing: 3px;
    font-size: 1.6rem;
    margin-top: 43px;
    margin-left: 3px;
    @media only screen and (max-width: 516px) {
        margin-top: 30px;
        letter-spacing: 1px;
    }
}

.p-single__link {
    letter-spacing: 3px;
    width: 281.45px;
    height: 73.12px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin: 204px auto 213px;
    background-color: #8FC31F;
    border-radius: 36px;
    color: white;
    -webkit-box-pack: center;
    justify-content: center;
    @media only screen and (max-width: 767px) {
        margin: 100px auto 190px;
    }
    @media only screen and (max-width: 516px) {
        margin: 60px auto 150px;
        letter-spacing: 1px;
        width: 244.45px;
        height: 60.12px;
    }
    img {
        margin-right: 15px;
        width: 24.32px;
    }
}

.p {
    &-policy__main {
        padding: 0 20px;
    }
    &-policy__meta {
        margin-left: 3px;
        @media only screen and (max-width: 767px) {
            margin-left: 0;
        }
    }
    &-policy__desc {
        font-size: 1.5rem;
        margin: 107px 0 35px;
        line-height: 2;
        @media only screen and (max-width: 767px) {
            margin: 60px 0 35px;
        }
        @media only screen and (max-width: 516px) {
            margin: 50px 0 25px;
        }
    }
    &-policy__content {
        line-height: 2;
        font-size: 1.6rem;
        p {
            letter-spacing: -0.3px;
            margin-bottom: 35px;
            @media only screen and (max-width: 767px) {
                letter-spacing: 0;
            }
            @media only screen and (max-width: 516px) {
                margin-bottom: 20px;
            }
        }
    }
    &-policy__tel {
        margin: -5px 0 184px;
        font-size: 2.4rem;
        @media only screen and (max-width: 516px) {
            margin: -5px 0 150px;
        }
        span {
            &:first-child {
                margin-right: 31px;
                @media only screen and (max-width: 516px) {
                    margin-right: 15px;
                }
            }
            &:last-child {
                letter-spacing: 2px;
                @media only screen and (max-width: 516px) {
                    letter-spacing: 1px;
                }
            }
        }
    }
}

.p {
    &-sdg {}
    &-sdg__main {
        padding: 0 20px;
        margin-bottom: 206px;
        @media only screen and (max-width: 767px) {
            margin-bottom: 180px;
        }
        @media only screen and (max-width: 516px) {
            margin-bottom: 150px;
        }
    }
    &-top2__english {}
    &-top2__japan {}
    &-sdg__content {
        margin: 51px 0 45px;
    }
    &-sdg__title {
        margin-bottom: 9px;
        letter-spacing: 1px;
    }
    &-sdg__text {
        letter-spacing: 1px;
        font-size: 1.6rem;
        line-height: 1.85;
    }
    &-sdg__sign {
        display: flex;
        -webkit-box-pack: end;
        justify-content: flex-end;
        margin: 25px 0 83px;
        letter-spacing: 0;
        font-size: 1.6rem;
        @media only screen and (max-width: 767px) {
            margin: 25px 0 45px;
        }
        @media only screen and (max-width: 516px) {
            margin: 25px 0 35px;
        }
    }
    &-sdg__part {
        margin-bottom: 90px;
        @media only screen and (max-width: 767px) {
            margin-bottom: 70px;
        }
        @media only screen and (max-width: 516px) {
            margin-bottom: 40px;
        }
    }
    &-sdg__top {
        display: flex;
        align-items: center;
        font-size: 2.8rem;
        color: #8FC31F;
        @media only screen and (max-width: 767px) {
            font-size: 2.4rem;
        }
        @media only screen and (max-width: 516px) {
            font-size: 2rem;
        }
        img {
            margin-left: -4px;
            width: 49.4px;
            margin-right: 13px;
            @media only screen and (max-width: 516px) {
                margin-left: -4px;
                width: 44.4px;
            }
        }
        span {
            margin-top: 5px;
            letter-spacing: 1.5px;
            @media only screen and (max-width: 516px) {
                letter-spacing: 0.5px;
            }
        }
    }
    &-sdg__image {
        margin-top: 24px;
        display: flex;
        margin-bottom: 50px;
        @media only screen and (max-width: 767px) {
            margin-bottom: 30px;
        }
    }
    &-sdg__item {
        width: 154.95px;
        margin-right: 26px;
        @media only screen and (max-width: 767px) {
            width: 120.95px;
        }
        @media only screen and (max-width: 516px) {
            width: calc(100% / 3 - 20px);
            margin-right: 20px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.p {
    &-about {}
    &-about__main {
        padding: 0 20px;
        margin-bottom: 205px;
        @media only screen and (max-width: 767px) {
            margin-bottom: 180px;
        }
        @media only screen and (max-width: 516px) {
            margin-bottom: 150px;
        }
    }
    &-top__container {}
    &-top2__english {}
    &-top2__japan {}
    &-about__bg {
        border-radius: 12px;
        overflow: hidden;
        margin: 67px 0 100px;
        @media only screen and (max-width: 767px) {
            margin: 67px 0;
        }
        @media only screen and (max-width: 516px) {
            margin: 50px 0 40px;
        }
    }
    &-about__list {}
    &-about__item {
        display: flex;
        border-bottom: 1px solid #8FC31F;
        padding: 44px 0;
        letter-spacing: 2px;
        align-items: center;
        @media only screen and (max-width: 516px) {
            padding: 25px 0;
            letter-spacing: 1px;
            flex-wrap: wrap;
        }
        span {
            &:first-child {
                width: 22%;
                @media only screen and (max-width: 767px) {
                    width: 135px;
                }
                @media only screen and (max-width: 516px) {
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
            &:last-child {
                width: 78%;
                font-size: 1.6rem;
                @media only screen and (max-width: 516px) {
                    width: 100%;
                }
            }
        }
    }
}