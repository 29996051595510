@charset "UTF-8";
/*------------------------------------------------------------
	Reset
------------------------------------------------------------*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, dialog, figure, footer, header,
nav, section,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
  display: block;
}

html {
  font-size: 62.5%;
}

body {
  -webkit-text-size-adjust: 100%;
}

body, table,
input, textarea, select, option,
h1, h2, h3, h4, h5, h6 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}

table,
input, textarea, select, option {
  line-height: 1.1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

:focus {
  outline: 0;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

img {
  vertical-align: top;
}

a,
a img {
  transition: 0.3s ease-in-out;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover,
a:hover img {
  opacity: 0.80;
  filter: alpha(opacity=80);
  -ms-filter: "alpha(opacity=80)";
}

img {
  max-width: 100%;
}

.sp-only {
  display: none;
}

@media only screen and (max-width: 767px) {
  .pc-only {
    display: none;
  }
  .sp-only {
    display: block;
  }
}

/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Layout .l-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
/* Header
------------------------------------------------------------*/
/* Main menu
------------------------------------------------------------*/
body.is-modal-open {
  overflow: hidden;
}

body.is-modal-open .l-header__nav-btn {
  display: none;
}

.c-header {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  color: white;
  padding: 0 20px;
}

.c-header__list {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  max-width: 1010px;
  margin: 33px auto;
  padding-right: 49px;
  align-items: center;
}

.c-header__home {
  font-size: 3.2rem;
  letter-spacing: 3px;
  z-index: 2;
}

@media only screen and (max-width: 767px) {
  .c-header__home {
    font-size: 2.8rem;
  }
}

.c-header__fix {
  width: 100%;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1148px;
  margin: 0 auto;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  height: 114px;
  -webkit-box-align: center;
  align-items: center;
  padding: 0 20px;
}

@media only screen and (max-width: 516px) {
  .c-header__fix {
    padding: 0 10px;
  }
}

.c-header__menu {
  margin-top: 51px;
  text-align: center;
  margin-bottom: 133px;
}

.c-header__menu li {
  margin-bottom: 24px;
}

.c-header__copy {
  letter-spacing: 1.8px;
}

.l-nav__button {
  display: flex;
  justify-content: flex-end;
  max-width: 1040px;
  margin: 27px auto;
  width: 100%;
  align-items: center;
  padding: 0 20px;
}

.l-header__nav-btn.sp {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .l-header__nav-btn.sp {
    display: block;
    margin-left: 40px;
  }
  .l-header__nav-btn.pc {
    display: none;
  }
}

#js-nav-btn {
  position: relative;
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  flex-direction: column;
  cursor: pointer;
}

#js-nav-btn.is-fixed {
  background-color: #8FC31F;
}

#js-nav-btn.is-fixed span {
  background-color: white;
}

#js-nav-btn.is-fixed b {
  color: white;
}

.l-header__nav-btn.act {
  background-color: unset;
}

.l-header__nav-btn span {
  display: block;
  background-color: #8FC31F;
  height: 2px;
  width: 26.42px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  margin-bottom: 4px;
}

.l-header__nav-btn b {
  display: block;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: .1em;
  font-size: 10px;
  text-align: center;
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  white-space: nowrap;
  font-size: 0.882rem;
  color: #8FC31F;
}

.l-header__nav-btn.act span {
  background-color: white;
}

.l-header__nav-btn.act b {
  color: white;
}

.l-header__nav-btn b::before {
  content: "MENU";
}

.l-header__nav-btn.act span {
  position: absolute;
  top: calc(50% - 1px);
  left: 4px;
  margin: 0;
}

.l-header__nav-btn.act span {
  left: 0;
  right: 0;
}

.l-header__nav-btn.act span:nth-child(1) {
  top: 12px;
  transform: rotate(45deg);
  height: 2px;
  width: 28px;
}

.l-header__nav-btn.act span:nth-child(2) {
  opacity: 0;
}

.l-header__nav-btn.act span:nth-child(3) {
  top: 12px;
  transform: rotate(-45deg);
  height: 2px;
  width: 28px;
}

.l-header__nav-btn.act b {
  display: none;
}

.l-header__nav-btn.act b::before {
  content: "CLOSE";
}

_:-ms-lang(x)::-ms-backdrop,
.l-header__nav-btn > b {
  transform: scale(0.9) translateX(-5%);
}

_:-ms-lang(x)::-ms-backdrop,
.l-header__nav-btn.act > b {
  transform: scale(0.9) translateX(-7%);
}

.l-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #8FC31F;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity 0.3s cubic-bezier(0, 0, 0.19, 0.99);
  transition: opacity 0.3s cubic-bezier(0, 0, 0.19, 0.99);
  overflow: auto;
  padding: 0 20px;
  overflow-x: hidden;
}

@media only screen and (max-width: 767px) {
  .l-nav {
    padding: 0;
  }
}

.l-nav.show {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
  opacity: 1;
  pointer-events: auto;
}

.l-nav__button .l-header__nav-btn.act {
  width: 28px;
  position: relative;
  display: -webkit-box;
  display: flex;
  height: 28px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/*------------------------------------------------------------
footer
------------------------------------------------------------*/
.c-footer {
  background-color: #8FC31F;
  padding: 49px 20px 33px;
  position: relative;
  color: white;
}

.c-footer__list {
  max-width: 841.62px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .c-footer__list {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.c-footer__home {
  font-size: 3.2rem;
  letter-spacing: 4px;
}

@media only screen and (max-width: 767px) {
  .c-footer__home {
    font-size: 2.8rem;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.c-footer__menu {
  width: 100%;
  max-width: 564.6px;
  justify-content: space-between;
  display: flex;
}

@media only screen and (max-width: 1024px) {
  .c-footer__menu {
    max-width: 531.6px;
  }
}

@media only screen and (max-width: 516px) {
  .c-footer__menu {
    max-width: 350px;
    margin: 0 auto;
    flex-wrap: wrap;
  }
  .c-footer__menu li {
    width: 40%;
    margin-bottom: 10px;
  }
  .c-footer__menu li:nth-child(even) {
    width: 60%;
  }
}

.c-footer__copy {
  text-align: center;
  font-size: 1.6rem;
  margin-top: 64px;
  letter-spacing: 3px;
}

@media only screen and (max-width: 516px) {
  .c-footer__copy {
    font-size: 1.5rem;
    margin-top: 53px;
    letter-spacing: 0px;
  }
}

.c-footer__top {
  width: 74.14px;
  position: absolute;
  cursor: pointer;
  right: 0;
  bottom: 0;
}

/*------------------------------------------------------------
sidebar
------------------------------------------------------------*/
/* Breadcrumb
------------------------------------------------------------*/
/* layout
------------------------------------------------------------*/
body {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 1.8rem;
  line-height: 1.5;
}

@media only screen and (max-width: 767px) {
  body {
    font-size: 1.6rem;
  }
}

/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Component .c-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
/*------------------------------------------------------------
btn
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
title
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
icon
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
form
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
text
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
navi
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
img
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
list
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
table
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
line
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
video
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
video
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
other
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Project .p-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
/*------------------------------------------------------------
Css of home page
------------------------------------------------------------*/
.p-top__gray {
  background-color: #E4E1DD;
  padding: 106px 20px 206px;
}

@media only screen and (max-width: 767px) {
  .p-top__gray {
    padding: 106px 20px 186px;
  }
}

@media only screen and (max-width: 516px) {
  .p-top__gray {
    padding: 80px 20px 120px;
  }
}

.p-top__container {
  max-width: 1000px;
  margin: 0 auto;
}

.p-top1 {
  background-image: url(../img/bg.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 600px;
  color: white;
  padding: 152px 20px;
}

.p-top1__list {
  max-width: 1000px;
  margin: 0 auto;
}

.p-top1__title {
  letter-spacing: 4px;
  font-size: 4.2rem;
  line-height: 1.6;
}

@media only screen and (max-width: 767px) {
  .p-top1__title {
    font-size: 3.2rem;
  }
}

@media only screen and (max-width: 516px) {
  .p-top1__title {
    letter-spacing: 2px;
    font-size: 3rem;
  }
}

.p-top1__text {
  letter-spacing: 2px;
  margin-top: 18px;
  line-height: 1.95;
  margin-left: 4px;
}

.p-top2__list {
  display: flex;
  justify-content: space-between;
  max-width: 953.18px;
}

@media only screen and (max-width: 767px) {
  .p-top2__list {
    flex-wrap: wrap;
  }
}

.p-top2__left {
  width: 26.5%;
}

@media only screen and (max-width: 767px) {
  .p-top2__left {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.p-top2__english {
  letter-spacing: 2px;
  font-size: 3.6rem;
}

@media only screen and (max-width: 767px) {
  .p-top2__english {
    font-size: 3rem;
  }
}

.p-top2__japan {
  margin-top: 5px;
  color: #8FC31F;
  letter-spacing: 2px;
}

.p-top2__bottom {
  display: flex;
  margin-top: 87px;
}

@media only screen and (max-width: 767px) {
  .p-top2__bottom {
    margin-top: 0;
  }
}

.p-top2__bottom span {
  margin-left: 3px;
  margin-right: 23px;
}

@media only screen and (max-width: 516px) {
  .p-top2__bottom span {
    margin-right: 15px;
  }
}

.p-top2__bottom img {
  width: 37.42px;
}

@media only screen and (max-width: 516px) {
  .p-top2__bottom img {
    width: 28.42px;
  }
}

.p-top2__right {
  margin-top: 17px;
  width: 73.5%;
}

@media only screen and (max-width: 767px) {
  .p-top2__right {
    width: 100%;
    margin-top: 50px;
  }
}

.p-top2__post {
  border-top: 1px solid;
  border-bottom: 1px solid;
  padding: 26px 25px 37px 19px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 516px) {
  .p-top2__post {
    padding: 26px 0;
  }
}

.p-top2__info {
  width: 86%;
}

.p-top2__date {
  margin-bottom: 11px;
  letter-spacing: 2px;
}

.p-top2__title {
  letter-spacing: 4px;
}

.p-top2__arrow {
  width: 27.89px;
}

.p-top3 {
  margin-top: 109px;
}

@media only screen and (max-width: 516px) {
  .p-top3 {
    margin-top: 80px;
  }
}

.p-top3__desc {
  margin: 29px 0 10px;
  line-height: 2;
  letter-spacing: 2px;
}

.p-top3__list {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  max-width: 913.37px;
  margin-top: 55px;
}

@media only screen and (max-width: 516px) {
  .p-top3__list {
    flex-wrap: wrap;
    margin-top: 45px;
  }
}

.p-top3__item {
  width: 43.5%;
}

@media only screen and (max-width: 1024px) {
  .p-top3__item {
    width: 46.5%;
  }
}

@media only screen and (max-width: 516px) {
  .p-top3__item {
    width: 100%;
    margin-bottom: 40px;
  }
}

.p-top3__title {
  font-size: 2.4rem;
  display: -webkit-box;
  display: flex;
  margin: 32px 0 14px;
  letter-spacing: 2px;
}

@media only screen and (max-width: 767px) {
  .p-top3__title {
    font-size: 2rem;
  }
}

.p-top3__title span {
  margin-right: 21px;
}

.p-top3__title img {
  width: 27.89px;
}

.p-top3__text {
  font-size: 1.6rem;
  letter-spacing: 1.5px;
  line-height: 2.2;
}

.p-top4 {
  margin-top: 133px;
}

@media only screen and (max-width: 767px) {
  .p-top4 {
    margin-top: 103px;
  }
}

@media only screen and (max-width: 516px) {
  .p-top4 {
    margin-top: 30px;
  }
}

/*------------------------------------------------------------
Css of home page
------------------------------------------------------------*/
.p-single__main {
  padding: 0 20px;
}

.p-single__list {
  display: flex;
  justify-content: space-between;
  max-width: 888.63px;
}

@media only screen and (max-width: 767px) {
  .p-single__list {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 767px) {
  .p-single__left {
    width: 100%;
  }
}

.p-single__post {
  width: 79%;
  margin-top: 15px;
}

@media only screen and (max-width: 767px) {
  .p-single__post {
    width: 100%;
    margin-top: 40px;
  }
}

.p-single__info {
  padding-bottom: 45px;
  border-bottom: 1px solid #8FC31F;
}

@media only screen and (max-width: 516px) {
  .p-single__info {
    padding-bottom: 20px;
  }
}

.p-single__date {
  letter-spacing: 2.7px;
}

@media only screen and (max-width: 516px) {
  .p-single__date {
    letter-spacing: 2px;
  }
}

.p-single__title {
  font-size: 2.4rem;
  margin-top: 3px;
  letter-spacing: 5px;
}

@media only screen and (max-width: 516px) {
  .p-single__title {
    font-size: 2.2rem;
    letter-spacing: 2px;
  }
}

.p-single__content {
  line-height: 1.75;
  letter-spacing: 3px;
  font-size: 1.6rem;
  margin-top: 43px;
  margin-left: 3px;
}

@media only screen and (max-width: 516px) {
  .p-single__content {
    margin-top: 30px;
    letter-spacing: 1px;
  }
}

.p-single__link {
  letter-spacing: 3px;
  width: 281.45px;
  height: 73.12px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin: 204px auto 213px;
  background-color: #8FC31F;
  border-radius: 36px;
  color: white;
  -webkit-box-pack: center;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .p-single__link {
    margin: 100px auto 190px;
  }
}

@media only screen and (max-width: 516px) {
  .p-single__link {
    margin: 60px auto 150px;
    letter-spacing: 1px;
    width: 244.45px;
    height: 60.12px;
  }
}

.p-single__link img {
  margin-right: 15px;
  width: 24.32px;
}

.p-policy__main {
  padding: 0 20px;
}

.p-policy__meta {
  margin-left: 3px;
}

@media only screen and (max-width: 767px) {
  .p-policy__meta {
    margin-left: 0;
  }
}

.p-policy__desc {
  font-size: 1.5rem;
  margin: 107px 0 35px;
  line-height: 2;
}

@media only screen and (max-width: 767px) {
  .p-policy__desc {
    margin: 60px 0 35px;
  }
}

@media only screen and (max-width: 516px) {
  .p-policy__desc {
    margin: 50px 0 25px;
  }
}

.p-policy__content {
  line-height: 2;
  font-size: 1.6rem;
}

.p-policy__content p {
  letter-spacing: -0.3px;
  margin-bottom: 35px;
}

@media only screen and (max-width: 767px) {
  .p-policy__content p {
    letter-spacing: 0;
  }
}

@media only screen and (max-width: 516px) {
  .p-policy__content p {
    margin-bottom: 20px;
  }
}

.p-policy__tel {
  margin: -5px 0 184px;
  font-size: 2.4rem;
}

@media only screen and (max-width: 516px) {
  .p-policy__tel {
    margin: -5px 0 150px;
  }
}

.p-policy__tel span:first-child {
  margin-right: 31px;
}

@media only screen and (max-width: 516px) {
  .p-policy__tel span:first-child {
    margin-right: 15px;
  }
}

.p-policy__tel span:last-child {
  letter-spacing: 2px;
}

@media only screen and (max-width: 516px) {
  .p-policy__tel span:last-child {
    letter-spacing: 1px;
  }
}

.p-sdg__main {
  padding: 0 20px;
  margin-bottom: 206px;
}

@media only screen and (max-width: 767px) {
  .p-sdg__main {
    margin-bottom: 180px;
  }
}

@media only screen and (max-width: 516px) {
  .p-sdg__main {
    margin-bottom: 150px;
  }
}

.p-sdg__content {
  margin: 51px 0 45px;
}

.p-sdg__title {
  margin-bottom: 9px;
  letter-spacing: 1px;
}

.p-sdg__text {
  letter-spacing: 1px;
  font-size: 1.6rem;
  line-height: 1.85;
}

.p-sdg__sign {
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  margin: 25px 0 83px;
  letter-spacing: 0;
  font-size: 1.6rem;
}

@media only screen and (max-width: 767px) {
  .p-sdg__sign {
    margin: 25px 0 45px;
  }
}

@media only screen and (max-width: 516px) {
  .p-sdg__sign {
    margin: 25px 0 35px;
  }
}

.p-sdg__part {
  margin-bottom: 90px;
}

@media only screen and (max-width: 767px) {
  .p-sdg__part {
    margin-bottom: 70px;
  }
}

@media only screen and (max-width: 516px) {
  .p-sdg__part {
    margin-bottom: 40px;
  }
}

.p-sdg__top {
  display: flex;
  align-items: center;
  font-size: 2.8rem;
  color: #8FC31F;
}

@media only screen and (max-width: 767px) {
  .p-sdg__top {
    font-size: 2.4rem;
  }
}

@media only screen and (max-width: 516px) {
  .p-sdg__top {
    font-size: 2rem;
  }
}

.p-sdg__top img {
  margin-left: -4px;
  width: 49.4px;
  margin-right: 13px;
}

@media only screen and (max-width: 516px) {
  .p-sdg__top img {
    margin-left: -4px;
    width: 44.4px;
  }
}

.p-sdg__top span {
  margin-top: 5px;
  letter-spacing: 1.5px;
}

@media only screen and (max-width: 516px) {
  .p-sdg__top span {
    letter-spacing: 0.5px;
  }
}

.p-sdg__image {
  margin-top: 24px;
  display: flex;
  margin-bottom: 50px;
}

@media only screen and (max-width: 767px) {
  .p-sdg__image {
    margin-bottom: 30px;
  }
}

.p-sdg__item {
  width: 154.95px;
  margin-right: 26px;
}

@media only screen and (max-width: 767px) {
  .p-sdg__item {
    width: 120.95px;
  }
}

@media only screen and (max-width: 516px) {
  .p-sdg__item {
    width: calc(100% / 3 - 20px);
    margin-right: 20px;
  }
  .p-sdg__item:last-child {
    margin-right: 0;
  }
}

.p-about__main {
  padding: 0 20px;
  margin-bottom: 205px;
}

@media only screen and (max-width: 767px) {
  .p-about__main {
    margin-bottom: 180px;
  }
}

@media only screen and (max-width: 516px) {
  .p-about__main {
    margin-bottom: 150px;
  }
}

.p-about__bg {
  border-radius: 12px;
  overflow: hidden;
  margin: 67px 0 100px;
}

@media only screen and (max-width: 767px) {
  .p-about__bg {
    margin: 67px 0;
  }
}

@media only screen and (max-width: 516px) {
  .p-about__bg {
    margin: 50px 0 40px;
  }
}

.p-about__item {
  display: flex;
  border-bottom: 1px solid #8FC31F;
  padding: 44px 0;
  letter-spacing: 2px;
  align-items: center;
}

@media only screen and (max-width: 516px) {
  .p-about__item {
    padding: 25px 0;
    letter-spacing: 1px;
    flex-wrap: wrap;
  }
}

.p-about__item span:first-child {
  width: 22%;
}

@media only screen and (max-width: 767px) {
  .p-about__item span:first-child {
    width: 135px;
  }
}

@media only screen and (max-width: 516px) {
  .p-about__item span:first-child {
    width: 100%;
    margin-bottom: 10px;
  }
}

.p-about__item span:last-child {
  width: 78%;
  font-size: 1.6rem;
}

@media only screen and (max-width: 516px) {
  .p-about__item span:last-child {
    width: 100%;
  }
}

/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Utility .u-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
