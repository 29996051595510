/* layout
------------------------------------------------------------*/

body {
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 1.8rem;
    line-height: 1.5;
    @media only screen and (max-width: 767px) {
        font-size: 1.6rem;
    }
}

//-------------------------------------------------------
main {}

section {}

//-------------------------------------------------------
.l-container {}

//-------------------------------------------------------
.c-mainvisual {}