/*------------------------------------------------------------
Css of home page
------------------------------------------------------------*/

.p {
    &-top__gray {
        background-color: #E4E1DD;
        padding: 106px 20px 206px;
        @media only screen and (max-width: 767px) {
            padding: 106px 20px 186px;
        }
        @media only screen and (max-width: 516px) {
            padding: 80px 20px 120px;
        }
    }
    &-top__container {
        max-width: 1000px;
        margin: 0 auto;
    }
    &-top1 {
        background-image: url(../img/bg.jpg);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 600px;
        color: white;
        padding: 152px 20px;
    }
    &-top1__list {
        max-width: 1000px;
        margin: 0 auto;
    }
    &-top1__title {
        letter-spacing: 4px;
        font-size: 4.2rem;
        line-height: 1.6;
        @media only screen and (max-width: 767px) {
            font-size: 3.2rem;
        }
        @media only screen and (max-width: 516px) {
            letter-spacing: 2px;
            font-size: 3rem;
        }
    }
    &-top1__text {
        letter-spacing: 2px;
        margin-top: 18px;
        line-height: 1.95;
        margin-left: 4px;
    }
    &-top2 {}
    &-top2__list {
        display: flex;
        justify-content: space-between;
        max-width: 953.18px;
        @media only screen and (max-width: 767px) {
            flex-wrap: wrap;
        }
    }
    &-top2__left {
        width: 26.5%;
        @media only screen and (max-width: 767px) {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    &-top2__english {
        letter-spacing: 2px;
        font-size: 3.6rem;
        @media only screen and (max-width: 767px) {
            font-size: 3rem;
        }
    }
    &-top2__japan {
        margin-top: 5px;
        color: #8FC31F;
        letter-spacing: 2px;
    }
    &-top2__bottom {
        display: flex;
        margin-top: 87px;
        @media only screen and (max-width: 767px) {
            margin-top: 0;
        }
        span {
            margin-left: 3px;
            margin-right: 23px;
            @media only screen and (max-width: 516px) {
                margin-right: 15px;
            }
        }
        img {
            width: 37.42px;
            @media only screen and (max-width: 516px) {
                width: 28.42px;
            }
        }
    }
    &-top2__right {
        margin-top: 17px;
        width: 73.5%;
        @media only screen and (max-width: 767px) {
            width: 100%;
            margin-top: 50px;
        }
    }
    &-top2__post {
        border-top: 1px solid;
        border-bottom: 1px solid;
        padding: 26px 25px 37px 19px;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        align-items: center;
        @media only screen and (max-width: 516px) {
            padding: 26px 0;
        }
    }
    &-top2__info {
        width: 86%;
    }
    &-top2__date {
        margin-bottom: 11px;
        letter-spacing: 2px;
    }
    &-top2__title {
        letter-spacing: 4px;
    }
    &-top2__arrow {
        width: 27.89px;
    }
    &-top3 {
        margin-top: 109px;
        @media only screen and (max-width: 516px) {
            margin-top: 80px;
        }
    }
    &-top3__desc {
        margin: 29px 0 10px;
        line-height: 2;
        letter-spacing: 2px;
    }
    &-top3__list {
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        max-width: 913.37px;
        margin-top: 55px;
        @media only screen and (max-width: 516px) {
            flex-wrap: wrap;
            margin-top: 45px;
        }
    }
    &-top3__item {
        width: 43.5%;
        @media only screen and (max-width: 1024px) {
            width: 46.5%;
        }
        @media only screen and (max-width: 516px) {
            width: 100%;
            margin-bottom: 40px;
        }
    }
    &-top3__image {}
    &-top3__title {
        font-size: 2.4rem;
        display: -webkit-box;
        display: flex;
        margin: 32px 0 14px;
        letter-spacing: 2px;
        @media only screen and (max-width: 767px) {
            font-size: 2rem;
        }
        span {
            margin-right: 21px;
        }
        img {
            width: 27.89px;
        }
    }
    &-top3__text {
        font-size: 1.6rem;
        letter-spacing: 1.5px;
        line-height: 2.2;
    }
    &-top4 {
        margin-top: 133px;
        @media only screen and (max-width: 767px) {
            margin-top: 103px;
        }
        @media only screen and (max-width: 516px) {
            margin-top: 30px;
        }
    }
}